<template>
    <svg
        width="202"
        height="202"
        viewBox="0 0 202 202"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M100.65 201C155.879 201 200.65 156.228 200.65 101C200.65 45.7715 155.879 1 100.65 1C45.4219 1 0.650391 45.7715 0.650391 101C0.650391 156.228 45.4219 201 100.65 201Z"
            stroke="#8950C0"
            stroke-dasharray="5 5"
        />
        <path
            d="M123.062 131.772L111.859 140.047C111.16 140.563 110.747 141.381 110.747 142.253V149.529C110.747 151.771 113.286 153.063 115.084 151.735L124.675 144.649L134.266 151.735C136.064 153.063 138.6 151.771 138.6 149.529V142.253C138.6 141.381 138.189 140.563 137.49 140.047L126.286 131.772C125.325 131.063 124.02 131.063 123.062 131.772Z"
            fill="url(#paint0_linear_3_175)"
        />
        <path
            d="M100.65 188.912C149.203 188.912 188.562 149.552 188.562 101C188.562 52.4475 149.203 13.0879 100.65 13.0879C52.0979 13.0879 12.7383 52.4475 12.7383 101C12.7383 149.552 52.0979 188.912 100.65 188.912Z"
            fill="url(#paint1_linear_3_175)"
        />
        <path
            d="M124.4 128.599C121.222 112.227 106.809 99.8618 89.5012 99.8618C72.1957 99.8618 57.7803 112.227 54.6023 128.599C53.9143 132.139 56.699 135.409 60.3056 135.409H118.69C122.295 135.409 125.081 132.139 124.393 128.599H124.4Z"
            fill="url(#paint2_linear_3_175)"
        />
        <path
            d="M89.5009 100.961C106.011 100.961 119.806 112.574 123.171 128.073L123.314 128.809C123.866 131.647 121.628 134.31 118.69 134.31H60.3053C57.3669 134.31 55.1295 131.647 55.6811 128.809C58.7625 112.943 72.7317 100.961 89.5009 100.961Z"
            stroke="url(#paint3_linear_3_175)"
        />
        <path
            d="M93.5424 91.042C103.026 88.8038 108.898 79.3018 106.661 69.819C104.421 60.3359 94.9204 54.4627 85.4369 56.7009C75.9534 58.9392 70.0809 68.4412 72.3183 77.924C74.5578 87.4071 84.0589 93.2803 93.5424 91.042Z"
            fill="url(#paint4_linear_3_175)"
        />
        <path
            d="M105.591 70.0713C107.69 78.9636 102.182 87.8737 93.2901 89.9726C84.3978 92.0713 75.4879 86.5641 73.389 77.6717C71.2901 68.7794 76.7978 59.8693 85.6901 57.7704C94.5824 55.6717 103.492 61.179 105.591 70.0713Z"
            stroke="url(#paint5_linear_3_175)"
        />
        <g filter="url(#filter0_b_3_175)">
            <path
                d="M124.532 139.766C133.4 139.766 140.589 132.577 140.589 123.709C140.589 114.841 133.4 107.652 124.532 107.652C115.664 107.652 108.475 114.841 108.475 123.709C108.475 132.577 115.664 139.766 124.532 139.766Z"
                fill="url(#paint6_linear_3_175)"
            />
            <path
                d="M139.49 123.709C139.49 131.97 132.794 138.667 124.532 138.667C116.271 138.667 109.574 131.97 109.574 123.709C109.574 115.447 116.271 108.75 124.532 108.75C132.794 108.75 139.49 115.447 139.49 123.709Z"
                stroke="url(#paint7_linear_3_175)"
            />
        </g>
        <path
            d="M121.622 128.986L117.455 124.886L116.035 126.272L121.622 131.769L133.618 119.969L132.209 118.582L121.622 128.986Z"
            fill="white"
        />
        <defs>
            <filter
                id="filter0_b_3_175"
                x="103.475"
                y="102.652"
                width="42.1143"
                height="42.1145"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.5" />
                <feComposite
                    in2="SourceAlpha"
                    operator="in"
                    result="effect1_backgroundBlur_3_175"
                />
                <feBlend
                    mode="normal"
                    in="SourceGraphic"
                    in2="effect1_backgroundBlur_3_175"
                    result="shape"
                />
            </filter>
            <linearGradient
                id="paint0_linear_3_175"
                x1="118"
                y1="131.24"
                x2="142.424"
                y2="142.531"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#231035" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3_175"
                x1="223.727"
                y1="338.363"
                x2="78.6724"
                y2="7.59335"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="#F02247" />
                <stop offset="1" stop-color="#F02247" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3_175"
                x1="58.4484"
                y1="133.402"
                x2="83.9099"
                y2="83.2644"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" stop-opacity="0.2" />
                <stop offset="1" stop-color="white" stop-opacity="0.49" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_3_175"
                x1="59.4372"
                y1="102.658"
                x2="82.5185"
                y2="151.257"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_3_175"
                x1="77.8523"
                y1="92.6983"
                x2="101.127"
                y2="55.0447"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" stop-opacity="0.2" />
                <stop offset="1" stop-color="white" stop-opacity="0.49" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_3_175"
                x1="71.3275"
                y1="62.8823"
                x2="107.279"
                y2="86.8003"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_3_175"
                x1="110.288"
                y1="137.953"
                x2="138.776"
                y2="109.465"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" stop-opacity="0.2" />
                <stop offset="1" stop-color="white" stop-opacity="0.49" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_3_175"
                x1="110.741"
                y1="110.177"
                x2="137.587"
                y2="138.881"
                gradientUnits="userSpaceOnUse"
            >
                <stop stop-color="white" />
                <stop offset="1" stop-color="white" stop-opacity="0" />
            </linearGradient>
        </defs>
    </svg>
</template>
